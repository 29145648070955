
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  onIonViewDidLeave,
  toastController,
} from '@ionic/vue';
import { computed, defineComponent, inject, onMounted, ref, Ref } from 'vue';
import axios from 'axios';
import Lookup, { Country } from 'country-code-lookup';
import MessageBox from '@/components/MessageBox.vue';
import getGymsByCountry, { GymLocation } from '@/common/api/route/getGymsByCountry';
import { useRouter } from 'vue-router';
import { throttle } from 'lodash';
import {Box} from "@/components/wall-image-viewer/getBoundingBoxes";
import AutoComplete from '@/components/gym-selector/AutoComplete.vue';
import {uploadMedia} from "@/common/utils";

export default defineComponent({
  name: 'UploadRoute',
  components: {
    AutoComplete,
    MessageBox,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
  },
  setup() {
    const router = useRouter();
    const content: Ref<typeof IonContent | null> = ref(null);
    const msgBox: Ref<typeof MessageBox | null> = ref(null);
    const getAccessToken: () => Ref<string> = inject('getAccessToken', () => ref(''));
    const getWallBoxList: () => Ref<Array<Box>> = inject('getWallBoxList', () => ref([]));
    const wallNameText = ref('');
    const getRouteImageUri: () => Ref<string> = inject('getRouteImageUri', () => ref(''));
    const wallImage = getRouteImageUri();
    
    const countryNameList = ref<Array<Country>>([]);
    const gymLocationList = ref<Array<GymLocation>>([]);
    const selectedCountryIso3 = ref('CHE');
    const selectedGymLocation = ref(0);
    const userHasSelectedCountry = computed(() => selectedCountryIso3.value !== '');

    const asciiPattern = /^[ -~]+$/;

    const transformDaysAwayToYYYYMMDD = (daysAway: number): string => {
      const date = new Date(new Date().getTime() + daysAway * 24 * 60 * 60 * 1000);
      const mm = date.getMonth() + 1;
      const dd = date.getDate();
      // Format to yyyy-mm-dd;
      return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
    };
    const minRouteExpiry = computed(() => transformDaysAwayToYYYYMMDD(1));
    const maxRouteExpiry = computed(() => transformDaysAwayToYYYYMMDD(90));
    const expiryTime = ref('');

    onIonViewDidLeave(() => {
      msgBox.value?.close();
    });

    onMounted(() => {
      countryNameList.value = [...Lookup.countries.sort()];
    });

    const reset = () => {
      gymLocationList.value = [];
      selectedCountryIso3.value = '';
      selectedGymLocation.value = 0;
    };

    const onCountrySelect = async (country: Country) => {
      if (country) {
        selectedCountryIso3.value = country.iso3;
        await loadGyms();
      } else {
        reset();
      }
    };
    
    const loadGyms = async () => {
      gymLocationList.value = await getGymsByCountry(selectedCountryIso3.value);
      selectedCountryIso3.value === 'CHE' && (selectedGymLocation.value = 1);
    };

    const isValidRouteName = (routeName: string): boolean => {
      return routeName.length > 0 && routeName.length <= 120 && asciiPattern.test(routeName);
    };

    const showErrorMsg = (errorMsg: string): void => {
      msgBox.value?.setColor('danger');
      msgBox.value?.showMsg(errorMsg);
      content.value?.$el.scrollToTop(400);
    };

    const onSubmit = throttle(async (): Promise<boolean> => {
      msgBox.value?.close();

      if (!isValidRouteName(wallNameText.value)) {
        showErrorMsg('Route name has to be 1-120 ASCII characters');
        return false;
      }

      if (!userHasSelectedCountry.value || selectedGymLocation.value === 0) {
        showErrorMsg('Please select a country and gym');
        return false;
      }

      const wallImageId = await uploadMedia(wallImage.value, showErrorMsg);
      if(wallImageId === null){
        return false;
      }
      
      try {
        const routeResponse = await axios.post(
            process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/walls', {
              name: wallNameText.value,
              gym: selectedGymLocation.value,
              image: wallImageId,
              boxList: getWallBoxList().value
            }, {
              headers: {'Content-Type': 'application/json', Authorization: `Bearer ${getAccessToken().value}`},
            },
        );

        if (routeResponse.data.id) {
          const toast = await toastController.create({
            header: 'Route has been uploaded successfully',
            position: 'bottom',
            color: 'success',
            duration: 3000,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
              },
            ],
          });
          toast.present();
          router.push({ name: 'NewRoute', params: { selected: routeResponse.data.id } });
        } else {
          showErrorMsg('Unable to create route, please try again');
        }
      } catch (error:any) {
        if (error.response) {
          if (error.response.data.Message === 'Unregistered gym') {
            showErrorMsg(
                'Unregistered gym, please go to https://routemaker.rocks/gyms/request to register',
            );
          } else if (error.response.data.Message === 'Upload Limit Reached') {
            showErrorMsg(
                `Daily upload limit of ${error.response.data.Limit} reached, please wait till tomorrow or delete some routes from today`,
            );
          } else {
            showErrorMsg('Error: ' + error.response.data.detail);
          }
        } else if (error.request) {
          showErrorMsg('Bad request');
        } else {
          showErrorMsg('Error: ' + error.message);
        }
      }
      return true;
    }, 1000);
    
    loadGyms();

    return {
      wallImage,
      content,
      countryNameList,
      onCountrySelect,
      selectedGymLocation,
      selectedCountryIso3,
      userHasSelectedCountry,
      gymLocationList,
      onSubmit,
      msgBox,
      minRouteExpiry,
      maxRouteExpiry,
      expiryTime,
      routeNameText: wallNameText,
    };
  },
});
